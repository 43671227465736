import SectionHeading from "../../organisms/section-heading";
import classNames from "classnames";
import dynamic from "next/dynamic";
const FormattedText = dynamic(() => import("../../formatted-text").then((mod) => mod.FormattedText));

export function ParagraphSectionHeader({paragraph, ...props}) {
  if (!paragraph?.field_section_header_title) return <></>
  const headerType = paragraph?.field_section_header_type ? parseInt(paragraph?.field_section_header_type) : 2;
  const displayTitle = paragraph?.field_section_header_style_title?.length
    ? paragraph.field_section_header_style_title : undefined;

  return (
    <SectionHeading
      headerType={headerType}
      displayTitle={displayTitle}
      className={classNames("section-heading", {
        "max-w-4xl mx-auto mb-8": paragraph?.field_section_header_narrow,
        "mb-6": !paragraph?.field_section_header_narrow,
        "!mb-0": !paragraph?.field_section_header_description?.processed?.length,
      })}
      headingClassName={classNames({"leading-tight": headerType === 2})}
      title={paragraph.field_section_header_title}
      link={paragraph?.field_section_header_link}>
      {paragraph?.field_section_header_description?.processed && (
        <FormattedText processed={paragraph.field_section_header_description.processed}/>
      )}
    </SectionHeading>
  )
}
